import React from 'react';

import { paths } from './paths';
import { Redirect, Route } from 'react-router-dom';
import {
  CompanyOverview,
  Dashboard,
  Products,
  RecommendationPage,
  ComboFinancials,
  UserDashboard,
  Expiring,
  Commissions,
  Carriers,
  TransferAccount,
  Documents
} from '@/pages';
import { Loader } from '@/components';
import { useAuth } from '@/providers';
import { ProductTemplates } from '@/pages/ProductTemplates';
import { Attributes } from '@/pages/Attributes';
import { FileOverview } from '@/pages/Documents/FileOverview/FileOverview';

const AuthRedirect = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (!isLoading && isAuthenticated) return <Redirect to={paths.root} />;

  return <Loader />;
};

export const routes = [
  {
    path: '/',
    component: () => (
      <Route exact path="/">
        <Redirect to="/companies" />
      </Route>
    )
  },
  // we old path (overview) in Hubspot
  {
    path: '/overview/:companyId/:locationId?',
    component: () => (
      <Route
        path="/overview/:companyId/:locationId?"
        render={({ match }) => (
          <Redirect
            to={`/companies/${match.params.companyId}${match.params.locationId ? `/${match.params.locationId}` : ''}`}
          />
        )}
      />
    )
  },
  {
    path: paths.root,
    component: Dashboard,
    exact: true
  },
  {
    path: paths.users,
    component: UserDashboard
  },
  {
    path: paths.products,
    component: Products
  },
  {
    path: paths.productTemplatesItem,
    component: ProductTemplates,
    exact: true
  },
  {
    path: paths.productTemplates,
    component: ProductTemplates,
    exact: true
  },
  {
    path: paths.attributes,
    component: Attributes,
    exact: true
  },
  {
    path: paths.attributesItem,
    component: Attributes,
    exact: true
  },
  {
    path: paths.companyOverview,
    component: CompanyOverview
  },
  {
    path: paths.recommendation,
    component: RecommendationPage
  },
  {
    path: paths.comboFinancials,
    component: ComboFinancials
  },
  {
    path: paths.expiring,
    component: Expiring
  },
  { path: paths.authRedirect, component: AuthRedirect },
  {
    path: paths.commissions,
    component: Commissions
  },
  {
    path: paths.carriers,
    component: Carriers
  },
  {
    path: paths.transferAccount,
    component: TransferAccount
  },
  {
    path: paths.documents,
    component: Documents,
    exact: true
  },
  {
    path: paths.documentsFileOverview,
    component: FileOverview,
    exact: true
  }
];
