import { CategoryLabelMap } from '@/enums';
import { Button, Empty, Modal, Typography, Form, Select, Space } from 'antd';
import { useState } from 'react';
import { FileWithRelatedEntities } from '@/api';
import { LinkOutlined } from '@ant-design/icons';
import { useAssignFile } from '@/services';
import { useCompanyInsurances } from '@/services';
import { CarrierLogo } from '@/components/CarrierLogo';

export const ContractTab = ({
  insurance,
  file,
  company
}: FileWithRelatedEntities) => {
  const [isAssignContractModalOpen, setIsAssignContractModalOpen] =
    useState(false);

  const { data: companyInsurances } = useCompanyInsurances(
    company?.companyId ?? '',
    {
      enabled: !!company?.companyId
    }
  );

  return (
    <>
      {insurance ? (
        <>
          <Typography.Paragraph strong style={{ marginBottom: '8px' }}>
            Insurance category
          </Typography.Paragraph>
          <Typography.Paragraph>
            {CategoryLabelMap[insurance.categoryId]}
          </Typography.Paragraph>

          <Typography.Paragraph strong style={{ marginBottom: '8px' }}>
            Policy number
          </Typography.Paragraph>
          <Typography.Paragraph copyable>
            {insurance.policyNumber}
          </Typography.Paragraph>

          <Button
            icon={<LinkOutlined />}
            onClick={() => setIsAssignContractModalOpen(true)}
          >
            Assign to another contract
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Empty description="No contract assigned to this file">
            <Button
              icon={<LinkOutlined />}
              onClick={() => setIsAssignContractModalOpen(true)}
            >
              Assign contract
            </Button>
          </Empty>
        </div>
      )}

      <AssignContractModal
        currentInsurance={insurance ?? null}
        isOpen={isAssignContractModalOpen}
        onClose={() => setIsAssignContractModalOpen(false)}
        fileId={file.fileId}
        insurances={companyInsurances || []}
      />
    </>
  );
};

function AssignContractModal({
  isOpen,
  onClose,
  fileId,
  currentInsurance,
  insurances = []
}: {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
  currentInsurance: { insuranceId: string } | null;
  insurances: {
    name: string | null;
    insurance_id: string;
    category_id: string;
    policy_number: string | null;
    carrier: {
      name: string | null;
      logo: string | null;
    };
  }[];
}) {
  const [form] = Form.useForm();
  const assignContract = useAssignFile(fileId);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Assign contract"
      okText="Assign"
      onOk={form.submit}
      okButtonProps={{ loading: assignContract.isLoading }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ insuranceId: currentInsurance?.insuranceId }}
        onFinish={(v) => {
          assignContract.mutate(
            {
              belongsToUuid: v.insuranceId,
              belongsToTable: 'insurance'
            },

            {
              onSuccess: () => {
                onClose();
                form.resetFields();
              }
            }
          );
        }}
      >
        <Form.Item
          label="Policy number"
          name="insuranceId"
          rules={[{ required: true, message: 'Please enter a policy number' }]}
        >
          <Select
            showSearch
            filterOption={(input, option) => {
              return (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            className="contract-selector"
            placeholder="Select a contract"
            value={currentInsurance?.insuranceId}
            allowClear
          >
            {insurances.map((insurance) => (
              <Select.Option
                key={insurance.insurance_id}
                id={insurance.insurance_id}
                label={[
                  insurance.name,
                  insurance.category_id,
                  insurance.policy_number,
                  insurance.carrier.name
                ].join(' ')}
              >
                <Space>
                  <CarrierLogo name={insurance.carrier.logo} />
                  <div>
                    <div>
                      <Typography.Text>
                        Name:{' '}
                        {insurance.name ? (
                          <Typography.Text strong>
                            {insurance.name}
                          </Typography.Text>
                        ) : (
                          '[Missing]'
                        )}
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text>
                        Category:{' '}
                        {insurance.category_id ? (
                          <Typography.Text strong>
                            {CategoryLabelMap[insurance.category_id]}
                          </Typography.Text>
                        ) : (
                          '[Missing]'
                        )}
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text>
                        Carrier:{' '}
                        {insurance.carrier.name ? (
                          <Typography.Text strong>
                            {insurance.carrier.name}
                          </Typography.Text>
                        ) : (
                          '[Missing]'
                        )}
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text>
                        Policy:{' '}
                        {insurance.policy_number ? (
                          <Typography.Text strong>
                            {insurance.policy_number}
                          </Typography.Text>
                        ) : (
                          '[Missing]'
                        )}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
