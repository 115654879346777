import React, { useMemo, useState, useEffect } from 'react';

import './styles.scss';
import {
  Form,
  Button,
  Input,
  Popconfirm,
  Select,
  Typography,
  Modal
} from 'antd';
import { CategoryLabelMap } from '@/enums';
import {
  useFinancials,
  useUpdateInsuranceFinancial,
  useInsurance
} from '@/services';
import { Datepicker, Loader, Table, Uploader } from '@/components';
import {
  both,
  complement,
  evolve,
  keys,
  map,
  not,
  pipe,
  propEq,
  reject,
  when
} from 'ramda';
import { FinancialsToCsv } from './FinancialsToCsv';
import {
  capitalize,
  isNumber,
  timestampToDateString,
  useItemDialog,
  useLocalStorage
} from '@/utils';

const { Search } = Input;

const { Option } = Select;

const transform = pipe(
  map(({ MD, company, insuranceFilesNr, ...item }) => ({
    ...item,
    MD: MD.length
      ? {
          fullName: `${MD[0].userName} ${MD[0].userSurname}`
        }
      : undefined,
    company: {
      ...company,
      poaFile: { createdAt: company?.poaFile?.createdAt || '' }
    },
    comissionTotal: ((item.netPrice * item.commission) / 100).toFixed(2),
    insuranceFilesNr: insuranceFilesNr.length
  }))
);

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? (
      <Form.Item name={dataIndex} style={{ margin: 0 }}>
        {inputType === 'number' ? (
          <Input type="number" />
        ) : inputType === 'date' ? (
          <Datepicker />
        ) : inputType === 'select' ? (
          <Select>
            {options.map(({ text, value }) => (
              <Option key={value} value={value}>
                {text}
              </Option>
            ))}
          </Select>
        ) : (
          <Input />
        )}
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

const makeBody = pipe(
  evolve({
    startDate: when(isNumber, timestampToDateString),
    endDate: when(isNumber, timestampToDateString),
    commissionPaidAt: when(isNumber, timestampToDateString),
    contractPaymentNextDate: when(isNumber, timestampToDateString)
  }),
  reject(both(not, complement(isNumber)))
);

export const ComboFinancials = () => {
  const { data, isLoading, isRefetching, isFetching } = useFinancials({
    select: transform
  });
  const [rowData, setRowData] = useState([]);
  const [accountManagerFilters, setAccountManagerFilters] = useState([]);
  const [search, setSearch] = useLocalStorage('financialsSearchValue', '');
  const [editingId, setEditingId] = useState('');
  const [form] = Form.useForm();
  const updateMutation = useUpdateInsuranceFinancial();
  const {
    item: insId,
    isOpen,
    openItemDialog,
    closeItemDialog
  } = useItemDialog();
  const [sortsAndFilters, setSortsAndFilters] = useLocalStorage(
    'financialsSortAndFilters',
    {
      sorts: {},
      filters: {}
    }
  );

  useEffect(() => {
    setRowData(data);
    setAccountManagerFilters(
      [
        ...new Map(
          data
            ?.map(({ company: { accountManager } }) => ({
              text: `${accountManager?.email}`,
              value: accountManager?.auth0
            }))
            .map((item) => [item['value'], item])
        ).values()
      ].filter(({ value }) => !!value)
    );
  }, [isRefetching, isLoading, isFetching]);

  const isEditing = propEq('insuranceId', editingId);

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingId(record.insuranceId);
  };

  const cancelEditMode = () => {
    setEditingId('');
  };

  const save = (key) => {
    const values = form.getFieldsValue();
    if (values.closingCommission === '') {
      updateMutation.mutateAsync({
        insuranceId: key,
        ...makeBody(values),
        closingCommission: null
      });
    } else
      updateMutation.mutateAsync({ insuranceId: key, ...makeBody(values) });

    setRowData(
      map((record) =>
        record.insuranceId === key ? { ...record, ...values } : record
      )
    );
    setEditingId('');
  };

  const records = useMemo(
    () =>
      rowData?.filter(
        ({ name, carrierName, company, policyNumber }) =>
          name?.toLowerCase().includes(search.toLowerCase()) ||
          carrierName?.toLowerCase().includes(search.toLowerCase()) ||
          company.name?.toLowerCase().includes(search.toLowerCase()) ||
          policyNumber?.toLowerCase().includes(search.toLowerCase())
      ),
    [search, JSON.stringify(rowData)]
  );

  const columns = [
    {
      title: '',
      fixed: 'left',
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.insuranceId)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancelEditMode}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingId !== ''}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      }
    },
    {
      fixed: 'left',
      width: 150,
      title: 'Company Name',
      dataIndex: ['company', 'name'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['company', 'name'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.company?.name?.localeCompare(b?.company?.name)
    },
    {
      title: 'Company won date',
      width: 150,
      fixed: 'left',
      dataIndex: ['company', 'poaFile'],
      render: (e) =>
        new Date(e?.createdAt).toLocaleDateString() === 'Invalid Date' ||
        e?.createdAt === null
          ? ''
          : new Date(e.createdAt).toLocaleDateString(),
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['company', 'poaFile'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) =>
        Date.parse(a?.company?.poaFile?.createdAt) -
        Date.parse(b?.company?.poaFile?.createdAt)
    },
    {
      fixed: 'left',
      width: 150,
      title: 'Location Name',
      dataIndex: ['location', 'name'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['location', 'name'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.location?.name?.localeCompare(b?.location?.name)
    },
    {
      width: 150,
      title: 'Md Name',
      dataIndex: ['MD', 'fullName'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['MD', 'fullName'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.MD?.fullname?.localeCompare(b?.MD?.fullname)
    },
    {
      width: 200,
      title: 'Address',
      dataIndex: ['location', 'address', 'googleLocation'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['location', 'address', 'googleLocation'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) =>
        a?.location?.address?.googleLocation?.localeCompare(
          b?.location?.address?.googleLocation
        )
    },
    {
      width: 150,
      title: 'Date of birth',
      dataIndex: ['owner', 'userBirthdate'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['owner', 'userBirthdate'])
          ? sortsAndFilters.sorts.order
          : null,
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || !e
          ? ''
          : new Date(e).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a) - Date.parse(b)
    },
    {
      width: 150,
      title: 'Owner Email',
      dataIndex: ['owner', 'email'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['owner', 'email'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.owner?.email?.localeCompare(b?.owner?.email)
    },
    {
      width: 150,
      title: 'Carrier name',
      dataIndex: 'carrierName',
      sortOrder:
        sortsAndFilters.sorts.field === 'carrierName'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.carrierName?.localeCompare(b?.carrierName)
    },
    {
      width: 150,
      title: 'Product Type',
      dataIndex: 'categoryId',
      render: (category) => CategoryLabelMap[category],
      sorter: (a, b) =>
        CategoryLabelMap[a.categoryId]?.localeCompare(
          CategoryLabelMap[b.categoryId]
        ),
      sortOrder:
        sortsAndFilters.sorts.field === 'categoryId'
          ? sortsAndFilters.sorts.order
          : null,
      filters: [
        {
          text: 'Liability insurance',
          value: 'LI'
        },
        {
          text: 'Content insurance',
          value: 'CO'
        },
        {
          text: 'Other insurances',
          value: 'other'
        }
      ],
      filteredValue: sortsAndFilters.filters.categoryId || null,
      onFilter: (value, { categoryId }) => {
        if (value === 'other') return !['LI', 'CO'].includes(categoryId);

        return categoryId === value;
      }
    },
    {
      width: 150,
      title: 'Tariff',
      dataIndex: 'name',
      sortOrder:
        sortsAndFilters.sorts.field === 'name'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.name?.localeCompare(b?.name)
    },
    {
      title: 'Insurance Created',
      width: 150,
      dataIndex: 'insuranceCreatedAt',
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || e === null
          ? ''
          : new Date(e).toLocaleDateString(),
      sortOrder:
        sortsAndFilters.sorts.field === 'insuranceCreatedAt'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) =>
        Date.parse(a?.insuranceCreatedAt) - Date.parse(b?.insuranceCreatedAt)
    },
    {
      width: 150,
      title: 'Policy nr.',
      dataIndex: 'policyNumber',
      sortOrder:
        sortsAndFilters.sorts.field === 'policyNumber'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a, b) => a?.policyNumber?.localeCompare(b?.policyNumber),
      editable: true
    },
    {
      width: 150,
      title: 'New insurance',
      dataIndex: 'consultationProtocolExist',
      render: (e) => (e ? 'Yes' : 'No'),
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: sortsAndFilters.filters.consultationProtocolExist || null,
      onFilter: (value, { consultationProtocol }) =>
        !!keys(consultationProtocol)?.length === value
    },
    {
      width: 180,
      title: 'Start date by admin',
      dataIndex: 'startDate',
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || e === ''
          ? ''
          : new Date(e).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a) - Date.parse(b),
      sortOrder:
        sortsAndFilters.sorts.field === 'startDate'
          ? sortsAndFilters.sorts.order
          : null,
      editable: true,
      inputType: 'date'
    },
    {
      width: 180,
      title: 'Term date',
      dataIndex: 'endDate',
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || e === ''
          ? ''
          : new Date(e).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a) - Date.parse(b),
      sortOrder:
        sortsAndFilters.sorts.field === 'endDate'
          ? sortsAndFilters.sorts.order
          : null,
      editable: true,
      inputType: 'date'
    },
    {
      width: 180,
      title: 'Hauptfälligkeit',
      dataIndex: 'contractPaymentNextDate',
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || e === ''
          ? ''
          : new Date(e).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a) - Date.parse(b),
      sortOrder:
        sortsAndFilters.sorts.field === 'contractPaymentNextDate'
          ? sortsAndFilters.sorts.order
          : null,
      editable: true,
      inputType: 'date'
    },
    {
      width: 150,
      title: 'Access to uploaded docs',
      dataIndex: 'insuranceFilesNr',
      render: (e, { insuranceId }) => (
        <Button type="link" onClick={() => openItemDialog(insuranceId)}>
          {e ? 'Yes' : 'No'}
        </Button>
      ),
      filters: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      filteredValue: sortsAndFilters.filters.insuranceFilesNr || null,
      onFilter: (value, { insuranceFilesNr }) =>
        Boolean(insuranceFilesNr) === value
    },
    {
      width: 150,
      title: 'Initial commission received',
      dataIndex: 'initialCommisionReceived',
      render: (e) => (e ? 'Yes' : 'No'),
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      filteredValue: sortsAndFilters.filters.initialCommisionReceived || null,
      onFilter: (value, { initialCommisionReceived }) =>
        +initialCommisionReceived === value,
      editable: true,
      inputType: 'select'
    },
    {
      width: 150,
      title: 'Customer Status',
      dataIndex: ['company', 'customerStatus'],
      render: (e) => (e ? capitalize(e) : e)
    },
    {
      width: 150,
      title: 'Account Manager',
      dataIndex: ['company', 'accountManager', 'auth0'],
      render: (
        _,
        { company: { accountManager: { email } = { email: '' } } }
      ) => {
        return `${email ?? ''}`;
      },
      filters: accountManagerFilters,
      filteredValue:
        sortsAndFilters.filters['company.accountManager.auth0'] || null,
      onFilter: (
        value,
        {
          company: {
            accountManager: { auth0 }
          }
        }
      ) => {
        return auth0 === value;
      }
    },
    {
      width: 150,
      title: 'Payment period',
      dataIndex: 'paymentPeriod',
      filters: [
        { text: 'monatlich', value: 'monthly' },
        { text: 'vierteljährlich', value: 'quarterly' },
        { text: 'halbjährlich', value: 'half-yearly' },
        { text: 'jährlich', value: 'yearly' },
        { text: 'einmalig', value: 'once' },
        { text: 'beitragsfrei', value: 'non-contributory' }
      ],
      onFilter: (value, { paymentPeriod }) => paymentPeriod === value,
      editable: true,
      filteredValue: sortsAndFilters.filters.paymentPeriod || null,
      render: (e) =>
        [
          { text: 'monatlich', value: 'monthly' },
          { text: 'vierteljährlich', value: 'quarterly' },
          { text: 'halbjährlich', value: 'half-yearly' },
          { text: 'jährlich', value: 'yearly' },
          { text: 'einmalig', value: 'once' },
          { text: 'beitragsfrei', value: 'non-contributory' }
        ].find(({ value }) => value === e)?.text,
      inputType: 'select'
    },
    {
      width: 150,
      title: 'Insurance contract type',
      dataIndex: 'contractType',
      filters: [
        { text: 'Eigen', value: 'eigen' },
        { text: 'Fremd', value: 'fremd' },
        { text: 'Korrespondenz', value: 'korrespondenz' },
        { text: 'Offen / unklar', value: 'unklar' }
      ],
      filteredValue: sortsAndFilters.filters.contractType || null,
      onFilter: (value, { contractType }) => contractType === value,
      editable: true,
      inputType: 'select',
      render: (e) =>
        [
          { text: 'Eigen', value: 'eigen' },
          { text: 'Fremd', value: 'fremd' },
          { text: 'Korrespondenz', value: 'korrespondenz' },
          { text: 'Offen / unklar', value: 'unklar' }
        ].find(({ value }) => value === e)?.text
    },
    {
      width: 150,
      title: 'Contract status',
      dataIndex: 'contractStatus',
      filteredValue: sortsAndFilters.filters.contractStatus || null,
      filters: [
        { text: 'Aktiv', value: 'aktiv' },
        { text: 'Storniert', value: 'storniert' },
        { text: 'Ruhend', value: 'ruhend' },
        { text: 'Abgelaufen', value: 'ablauf' },
        { text: 'Antrag', value: 'antrag' }
      ],
      onFilter: (value, { contractStatus }) => contractStatus === value,
      editable: true,
      inputType: 'select',
      render: (e) =>
        [
          { text: 'Aktiv', value: 'aktiv' },
          { text: 'Storniert', value: 'storniert' },
          { text: 'Ruhend', value: 'ruhend' },
          { text: 'Abgelaufen', value: 'ablauf' },
          { text: 'Antrag', value: 'antrag' },
          { label: 'Ablehnung', value: 'ablehnung' }
        ].find(({ value }) => value === e)?.text
    },
    {
      width: 150,
      title: 'Gross Total Yearly',
      dataIndex: 'grossPrice',
      sorter: (a, b) => a.grossPrice - b.grossPrice,
      sortOrder:
        sortsAndFilters.sorts.field === 'grossPrice'
          ? sortsAndFilters.sorts.order
          : null
    },
    {
      width: 150,
      title: 'Net Total',
      dataIndex: 'netPrice',
      sorter: (a, b) => a.netPrice - b.netPrice,
      editable: true,
      sortOrder:
        sortsAndFilters.sorts.field === 'netPrice'
          ? sortsAndFilters.sorts.order
          : null
    },
    {
      width: 150,
      title: 'Commission Rate (%)',
      dataIndex: 'commission',
      sorter: (a, b) => a?.commission - b?.commission,
      editable: true,
      inputType: 'number',
      sortOrder:
        sortsAndFilters.sorts.field === 'commission'
          ? sortsAndFilters.sorts.order
          : null
    },
    {
      width: 150,
      title: 'Commission total (€)',
      dataIndex: 'comissionTotal',
      sorter: (a, b) => (a.comissionTotal = b.comissionTotal),
      sortOrder:
        sortsAndFilters.sorts.field === 'comissionTotal'
          ? sortsAndFilters.sorts.order
          : null
    },
    {
      width: 180,
      title: 'Closing commissions (€)',
      dataIndex: 'closingCommission',
      editable: true,
      inputType: 'number',
      sorter: (a, b) => a.closingCommission - b.closingCommission,
      sortOrder:
        sortsAndFilters.sorts.field === 'closingCommission'
          ? sortsAndFilters.sorts.order
          : null
    },
    {
      width: 180,
      title: 'Commission Paid Date',
      dataIndex: 'commissionPaidAt',
      render: (e) =>
        new Date(e).toLocaleDateString() === 'Invalid Date' || e === ''
          ? ''
          : new Date(e).toLocaleDateString(),
      sorter: (a, b) => Date.parse(a) - Date.parse(b),
      sortOrder:
        sortsAndFilters.sorts.field === 'commissionPaidAt'
          ? sortsAndFilters.sorts.order
          : null,
      editable: true,
      inputType: 'date'
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.filters
      })
    };
  });

  if (isLoading) return <Loader />;

  const onFilesModalClose = (insuranceFilesNr = 0) => {
    setRowData(
      map((record) =>
        record.insuranceId === insId ? { ...record, insuranceFilesNr } : record
      )
    );
    closeItemDialog();
  };

  return (
    <div>
      <div className="dashboard-control-panel">
        <Search
          defaultValue={search}
          size="large"
          placeholder="Search by tariff, carrier name, policy number, company name or email address"
          className="dashboardSearch"
          onSearch={(e) => {
            setSearch(e);
          }}
        />
      </div>
      <FinancialsToCsv
        columns={columns.slice(1)}
        records={data}
        isLoading={isLoading || isRefetching || isFetching}
      />
      <Form component={false} form={form}>
        <div className="table-container">
          <Table
            scroll={{ x: 2300, y: 'calc(100vh - 340px)' }}
            setSortsAndFilters={setSortsAndFilters}
            rowKey="insuranceId"
            paginationKey="financialsPagination"
            columns={mergedColumns}
            records={records}
            onPaginationChange={cancelEditMode}
            components={{
              body: {
                cell: EditableCell
              }
            }}
          />
        </div>
      </Form>
      {isOpen && <FilesModal insuranceId={insId} onClose={onFilesModalClose} />}
    </div>
  );
};

const FilesModal = ({ insuranceId, onClose }) => {
  const { data, isLoading } = useInsurance(insuranceId);

  if (isLoading) return <Loader />;

  return (
    <Modal
      centered
      footer={false}
      title="Insurance files"
      className="add-company-modal"
      open
      closable
      onCancel={() => onClose(data.files?.length)}
    >
      <Uploader
        max={6}
        files={data.files || []}
        queryKeys={['insurance', insuranceId]}
        belongsTo={insuranceId}
        type="insurance"
      />
    </Modal>
  );
};
