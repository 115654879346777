import { autoRecommendationKeys } from '@/enums';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';
import { Carrier } from '@/types/carrier';
import { UploadedFile } from '@/types/file';
import { ProductOptions } from '@/types/productOptions';
import { LocationType } from './location';

export type OfferTag = 'best_choice' | 'best_price' | 'best_coverage' | null;

export interface RecommendationViewResponse {
  bestChoice: string | null;
  categoryId: string;
  businessVertical: BusinessVerticalWithDefault;
  companyId: string | null;
  companyName: string | null;
  companyPoaSigned: boolean;
  convertedInsuranceId: string | null;
  createdAt: string;
  createdBy: string | null; // Auth0 ID
  emailSended: boolean | null;
  insuranceId: string | null;
  lastUpdatedBy: string | null;
  locationId: string;
  locationName: string | null;
  locationType: string | null;
  locationSubtypes: string[];
  notes?: Record<string, any>;
  recommendationId: string;
  recommendationProducts: RecommendationProduct[];
  sended: string | null;
  /**
   * @deprecated - please use recommendationTemplate instead
   */
  visibleAttributes: string[];
  recommendationTemplate: {
    version: number;
    attributes: { code: string; isVisible: boolean }[];
  } | null;
  searchCriteria: SearchCriteria | null;
  updatedAt: string;
  address: RiskAddress;
  partnerSource: string | null;
}

export interface RecommendationProduct {
  id: string;
  categoryId: string;
  name: string;
  description: string;
  deductible: number;
  risksInsured: LocationType | string; // string for legacy recommendations
  carrierData: Carrier;
  carrierId: string;
  createdAt: Date;
  updatedAt: Date;
  startDate?: Date | null;
  agreementNumber?: string | null;
  amountInsured: number | null;
  amountInsuredUnlimited?: boolean;
  parametersValues: {
    de: Record<string, string>;
    en: Record<string, string>;
  };
  yesNoValues: Record<string, 0 | 1 | undefined>;
  highlightText?: string[];
  highlightFields?: string[];
  highlightedAttributes: Record<string, boolean>;
  commission: number;
  insuranceProductId: string;
  recommendationProductId: string;
  recommendationId: string;
  netPrice?: number | null;
  grossPrice?: number | null;
  paymentPeriod?: string;
  firstLossSum: number | null;
  offerTag: OfferTag;
  discountValue: number | null;
  status: 'success' | 'error' | null;
  originalValues: {
    gross_price: number | null;
  } | null;
  recommendationProductFiles: UploadedFile[];
  lossOfEarnings: number | null;
  options?: ProductOptions;
  order: number;
  isSearchEnginePrice: boolean | null;
  isPriceVerified: boolean;
}

export interface Recommendation {
  locationId: string;
  createdAt: Date;
  updatedAt: Date;
  categoryId: string;
  insuranceId: string | null;
  emailSended: boolean | null;
  createdBy: string;
  sended: string | null;
  lastUpdatedBy: string;
  bestChoice: string | null;
  recommendationId: string;
  notes: Notes;
  convertedInsuranceId: string | null;
  /**
   * @deprecated - please use recommendationTemplate instead
   */
  visibleAttributes: string[] | null;
  searchCriteria: SearchCriteria;
  withdrawn: boolean;
  partnerSource: string | null;
}

export interface RiskAddress {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
}

export interface RecommendationProductResult {
  context: {
    price: PriceResult;
  };
}

export interface RecommendationProductPriceFetchResult
  extends RecommendationProduct {
  context: {
    price: PriceResult;
  };
}

export type PriceResult =
  | {
      status: 'success';
      location: LocationType;
      value: number;
      netValue?: number;
      discountValue: number;
      payload: unknown;
      carrierResponse: unknown;
      mixedBusinessContext?: unknown;
    }
  | {
      status: 'skipped';
    }
  | {
      status: 'error';
      message: string;
      context: unknown;
    };

export interface SearchCriteria {
  businessType: LocationType[];
  businessTypeOtherSpecification?: string | null;
  approxTurnover: number | null;
  salary: number | null;
  fullTimeEmployeesCount: number | null;
  partTimeEmployeesCount: number | null;
  miniJobbersCount: number | null;
  ownersCount: number | null;
  startDate: Date | string | null;
  paymentPeriod?: string;
}

interface Notes {
  en: string;
  de: string;
}

export interface AddRecommendationMutationResult {
  data: Recommendation;
}

export type AutoRecommendationCategoryId =
  (typeof autoRecommendationKeys)[number];

export const isAutoRecommendationCategoryId = (
  categoryId: string | AutoRecommendationCategoryId
): categoryId is AutoRecommendationCategoryId =>
  autoRecommendationKeys.includes(categoryId as AutoRecommendationCategoryId);
