import { useState } from 'react';

export const usePdfTopbar = () => {
  const [scale, setScale] = useState(1.0);
  const zoomIn = () => setScale((prev) => prev + 0.25);
  const zoomOut = () => setScale((prev) => Math.max(0.5, prev - 0.25));

  return {
    scale,
    zoomIn,
    zoomOut
  };
};
