export const paths = {
  root: '/companies',
  users: '/users',
  products: '/products',
  productTemplates: '/product-templates',
  productTemplatesItem: '/product-templates/:productTemplateId',
  attributes: '/attributes',
  attributesItem: '/attributes/:attributeId',
  companyOverview: '/companies/:companyId/:locationId?',
  authRedirect: '/auth-redirect',
  recommendation: '/recommendation/:recommendationId',
  comboFinancials: '/combo-financials',
  expiring: '/expiring',
  commissions: '/commissions',
  carriers: '/carriers',
  transferAccount: '/transfer-account',
  documents: '/documents',
  documentsFileOverview: '/documents/:fileId'
};
