import React from 'react';

import {
  useDeleteFile,
  useDownloadFile,
  useFileCategories,
  useUpdateInsuranceFile,
  useUploadFiles
} from '@/services';
import { DandD } from '@/components/DragAndDrop';
import { fileCategories } from '@/enums/fileTypes';
import { FileListingExtended } from '@/components/Uploader/FileListingExtended';
import { FileListingSimple } from '@/components/Uploader/FileListingSimple';
import { UploaderFile } from '@/components/Uploader/types';

interface UploaderProps {
  max: number;
  maxUploadLimit?: number;
  disabled?: boolean;
  listingDisabled?: boolean;
  files: UploaderFile[];
  belongsTo: string;
  queryKeys: string[];
  type: string;
  extended?: boolean;
  category?: string;
  onFilesUpdate?: () => void;
}

const makeCategoriesOptions = (categories: string[]) => {
  const withLabels = categories.map((value) => ({
    value,
    label: fileCategories[value as keyof typeof fileCategories] ?? value
  }));

  withLabels.sort((a, b) => a.label.localeCompare(b.label));

  return [{ value: null, label: 'Uncategorized' }, ...withLabels];
};

export const Uploader = ({
  max,
  disabled,
  listingDisabled,
  maxUploadLimit,
  files = [],
  belongsTo,
  queryKeys,
  type,
  extended,
  category,
  onFilesUpdate
}: UploaderProps) => {
  const uploadFilesMutation = useUploadFiles(
    belongsTo,
    queryKeys,
    type,
    category
  );
  const deleteFileMutation = useDeleteFile(queryKeys);
  const previewFile = useDownloadFile();
  const { data = [] } = useFileCategories();
  const categoriesOptions = makeCategoriesOptions(data);

  // below kinda feels like a hack
  // we load files from the insurance endpoint so we need to invalidate the insurance query
  // TODO: we should pass update file as a callback probably so that caller decides what is the endpoint and what is invalidated
  const updateFileMutation = useUpdateInsuranceFile(belongsTo);

  const downloadFile = (name: string) => previewFile.mutate(name);
  const deleteFile = async (fileId: string) => {
    await deleteFileMutation.mutateAsync(fileId);
    onFilesUpdate?.();
  };
  const updateFile = async (
    fileId: string,
    updateData: {
      displayName?: string;
      category?: string;
      visibleToUser?: boolean;
    }
  ) => {
    await updateFileMutation.mutateAsync({ fileId, ...updateData });
    onFilesUpdate?.();
  };

  return (
    <div>
      <DandD
        multiple
        accept=".pdf,.jpg"
        maxMbSize={200}
        max={max}
        disabled={disabled}
        maxUploadLimit={maxUploadLimit}
        files={files}
        onChange={uploadFilesMutation.mutate}
      />
      {extended ? (
        <FileListingExtended
          files={files}
          isDisabled={Boolean(listingDisabled)}
          downloadFile={downloadFile}
          updateFile={updateFile}
          deleteFile={deleteFile}
          availableCategories={categoriesOptions}
        />
      ) : (
        <FileListingSimple
          files={files}
          downloadFile={downloadFile}
          deleteFile={deleteFile}
        />
      )}
    </div>
  );
};
