import {
  useDeleteCompany,
  useListCompanies,
  useUpdateCompany
} from '@/services';
import Table, { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { CompanyListItem } from '@/api';
import { useGoToPath } from '@/utils';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  SignatureOutlined
} from '@ant-design/icons';
import { Checkbox, Dropdown, Input, Modal } from 'antd';
import { format } from 'date-fns';
import { concat } from 'ramda';
import { useDebounce } from 'use-debounce';
import { AddCompany } from './AddCompanyModal';
import './styles.scss';
import { EditCompanyModal } from './EditCompanyModal';
import { useState } from 'react';

export const Dashboard = () => {
  const { current, setParams } = useUrlParams<{
    page: string;
    pageSize: string;
    searchQuery?: string;
  }>();

  const [debouncedSearchQuery] = useDebounce(current.searchQuery, 300);

  const { data, isLoading } = useListCompanies({
    page: current.page,
    pageSize: current.pageSize,
    searchQuery: debouncedSearchQuery
  });

  const goToCompanyOverview = useGoToPath(concat('/companies/'));

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          gap: 16,
          justifyContent: 'space-between'
        }}
      >
        <Input.Search
          placeholder="Enter company name or id"
          value={current.searchQuery}
          onChange={(e) =>
            setParams({ searchQuery: e.target.value, page: '1' })
          }
          style={{
            maxWidth: 900
          }}
          allowClear
          size="large"
        />

        <AddCompany
          onSuccess={({ companyId }) => goToCompanyOverview(companyId)}
        />
      </div>

      <Table
        rowKey="companyId"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.meta.totalItems,
          pageSize: data?.meta.pageSize,
          current: data?.meta.currentPage,
          showSizeChanger: true
        }}
        onChange={(paginationChange) => {
          setParams({
            page: String(paginationChange.current ?? 1),
            pageSize: String(paginationChange.pageSize ?? 10)
          });
        }}
      />
    </>
  );
};

const CompanyActions = ({
  companyId,
  companyName
}: {
  companyId: string;
  companyName: string;
}) => {
  const { mutate: deleteCompany, isLoading: isDeleting } =
    useDeleteCompany(companyId);

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              label: 'Edit',
              icon: <EditOutlined />,
              key: 'edit',
              onClick: () => setIsEditing(true)
            },
            {
              label: 'Delete',
              icon: <DeleteOutlined />,
              key: 'delete',
              onClick: () => {
                Modal.confirm({
                  title: `Are you sure you want to delete ${companyName}?`,
                  content: 'This action cannot be undone.',
                  okText: 'Yes, delete',
                  okType: 'danger',
                  cancelText: 'Cancel',
                  okButtonProps: { loading: isDeleting },
                  onOk: () => deleteCompany()
                });
              }
            }
          ]
        }}
      >
        <MenuOutlined />
      </Dropdown>

      {isEditing && (
        <EditCompanyModal
          visible={isEditing}
          onClose={() => setIsEditing(false)}
          id={companyId}
        />
      )}
    </>
  );
};

const IsTestCompanyCheckbox = ({
  isTest,
  companyId
}: {
  isTest: boolean;
  companyId: string;
}) => {
  const { mutate: updateCompany, isLoading } = useUpdateCompany(companyId);

  return (
    <Checkbox
      checked={isTest}
      disabled={isLoading}
      onChange={(e) => {
        updateCompany({ isTest: e.target.checked });
      }}
    />
  );
};

const columns: ColumnsType<CompanyListItem> = [
  {
    title: 'Company Name',
    dataIndex: 'name',
    width: 900,
    render: (name, { companyId }) => (
      <Link to={`/companies/${companyId}/`}>{name}</Link>
    )
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: 600,
    render: (_, { address }) =>
      `${address.street} ${address.streetNr}, ${address.postalCode} ${address.city}, ${address.country}`
  },
  {
    title: 'POA Signed',
    dataIndex: 'poaSigned',
    align: 'center',
    width: 150,
    render: (_, { poaSigned }) =>
      poaSigned ? (
        <SignatureOutlined style={{ fontSize: 18, color: '#1890ff' }} />
      ) : (
        <CloseOutlined style={{ fontSize: 18, color: '#f5222d' }} />
      )
  },
  {
    title: 'Created at',
    width: 150,
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => format(new Date(createdAt), 'MMM d, yyyy')
  },
  {
    title: 'Is Test',
    dataIndex: 'isTest',
    width: 100,
    align: 'center',
    render: (_, { isTest, companyId }) => (
      <IsTestCompanyCheckbox isTest={isTest} companyId={companyId} />
    )
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: 100,
    align: 'center',
    render: (_, { companyId, name }) => (
      <CompanyActions companyId={companyId} companyName={name} />
    )
  }
];
