export const downloadPdfBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = `${fileName.includes('.pdf') ? fileName : `${fileName}.pdf`}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const printPdfBlob = (blob: Blob) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = URL.createObjectURL(blob);
  document.body.appendChild(iframe);
  iframe.onload = () => {
    iframe?.contentWindow?.focus();
    iframe?.contentWindow?.print();
  };
};
