import { useUploadUnprocessedFiles } from '@/services';
import { useLocalStorage } from '@/utils';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Radio, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React from 'react';
import { FileList } from './FileList';
import './styles.scss';

export type DocumentUrlParams = {
  page: string;
  pageSize: string;
  searchQuery: string;
  processed: string;
  [key: string]: string;
};

const DEFAULT_PARAMS: DocumentUrlParams = {
  page: '1',
  pageSize: '10',
  searchQuery: '',
  processed: 'false'
};

export const Documents = () => {
  const { current, setParams } = useUrlParams<DocumentUrlParams>();
  const [storedPageSize, setStoredPageSize] = useLocalStorage(
    'fileListPageSize',
    DEFAULT_PARAMS.pageSize
  );

  // Initialize URL parameters on mount
  React.useEffect(() => {
    const hasRequiredParams = Object.keys(DEFAULT_PARAMS).every(
      (key) => current[key] !== undefined
    );

    if (!hasRequiredParams) {
      setParams({
        ...DEFAULT_PARAMS,
        ...current,
        pageSize: current.pageSize || storedPageSize || DEFAULT_PARAMS.pageSize
      });
    }
  }, []);

  const [fileList, setFileList] = React.useState<RcFile[]>([]);

  const resetFileList = () => setFileList([]);

  const uploadInboxFilesMutation = useUploadUnprocessedFiles();

  const handleUpload = () => {
    uploadInboxFilesMutation.mutateAsync(fileList).then(resetFileList);
  };

  const handleTabChange = (value: 'false' | 'true') => {
    setParams({ processed: value });
  };

  const handleSearchChange = (value: string) => {
    setParams({ searchQuery: value, page: '1' });
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setParams({
      page: String(page),
      pageSize: String(pageSize)
    });
    setStoredPageSize(String(pageSize));
  };

  return (
    <>
      <div className="documents-page">
        <h1 className="documents-page-title">
          {current.processed === 'true' ? 'Documents' : 'File Inbox'}
        </h1>
        <div className="upload-section">
          <Radio.Group
            onChange={(e) => handleTabChange(e.target.value)}
            value={current.processed || DEFAULT_PARAMS.processed}
          >
            <Radio.Button value="false">Inbox</Radio.Button>
            <Radio.Button value="true">Documents</Radio.Button>
          </Radio.Group>
          <Divider type="vertical" />
          <div className="upload-section-right">
            <Upload
              beforeUpload={(file: RcFile) => {
                setFileList((prev) => [...prev, file]);
                /**
                 * Prevent default behavior of the upload component;
                 * We want to handle the upload logic manually
                 */
                return false;
              }}
              fileList={fileList}
              accept=".pdf,.zip,.xml,application/zip,application/x-zip-compressed"
              multiple
              onRemove={(file) => {
                setFileList((prev) => prev.filter((f) => f !== file));
              }}
            >
              <Button icon={<UploadOutlined />}>Select Documents</Button>
            </Upload>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploadInboxFilesMutation.isLoading}
            >
              {uploadInboxFilesMutation.isLoading ? 'Uploading' : 'Upload'}
            </Button>
          </div>
        </div>

        <FileList
          page={Number(current.page || DEFAULT_PARAMS.page)}
          pageSize={Number(current.pageSize || DEFAULT_PARAMS.pageSize)}
          searchQuery={current.searchQuery}
          processed={current.processed === 'true'}
          onSearchChange={handleSearchChange}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </>
  );
};
