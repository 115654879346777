import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import './styles.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// @ts-expect-error manually copied, please check craco.config.js
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function range(count: number): number[] {
  return Array.from({ length: count }, (_v, k) => k + 1);
}

export const PdfDocument = ({
  pdf,
  width,
  className,
  scale = 1
}: {
  pdf: Blob;
  width?: number;
  className?: string;
  scale?: number;
}) => {
  const [pagesCount, setPagesCount] = useState<number>(0);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPagesCount(numPages);
  }

  return (
    <div className={className ?? 'pdf-document-wrapper'}>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {range(pagesCount).map((page) => (
          <Page width={width} key={page} pageNumber={page} scale={scale} />
        ))}
      </Document>
    </div>
  );
};
