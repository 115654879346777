import { CompanyListItem } from '@/api';
import { useAssignFile, useListCompanies } from '@/services';
import { Company } from '@/types/company';
import { formatAssessmentAddress } from '@/utils/address';
import { LinkOutlined } from '@ant-design/icons';
import { Alert, Button, Empty, Modal, Select, Spin, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { FileWithRelatedEntities } from '@/api';

export const CompanyTab = ({ company, file }: FileWithRelatedEntities) => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {company ? (
        <>
          <p style={{ fontWeight: 500, marginBottom: '8px' }}>Name</p>
          <Link to={`/companies/${company.companyId}`}>
            <p style={{ marginBottom: '8px' }}>{company.name}</p>
          </Link>
          <p style={{ fontWeight: 500, marginBottom: '8px' }}>Address</p>
          <p>{formatAssessmentAddress(company.address)}</p>

          <Button onClick={showModal} icon={<LinkOutlined />}>
            Assign to another company
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Empty description="No company assigned to this file">
            <Button onClick={showModal} icon={<LinkOutlined />}>
              Assign company
            </Button>
          </Empty>
        </div>
      )}

      {isOpen && (
        <AssignCompanyModal
          open={isOpen}
          setOpen={setIsOpen}
          preselectedCompany={company}
          fileId={file.fileId}
          companyNameHint={file.metadata?.customer?.lastname}
        />
      )}
    </>
  );
};

const mapCompaniesToOptions = (companies: CompanyListItem[]) =>
  companies.map((company) => ({
    label: `${company.name}, ${formatAssessmentAddress(company.address)}`,
    value: company.companyId
  }));

const AssignCompanyModal = ({
  open,
  setOpen,
  preselectedCompany,
  fileId,
  companyNameHint
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  preselectedCompany?: Pick<Company, 'name' | 'companyId'>;
  fileId: string;
  companyNameHint?: string;
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const { data: companies, isLoading } = useListCompanies({
    pageSize: '10',
    searchQuery: debouncedSearchQuery,
    page: '1'
  });

  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    preselectedCompany?.companyId ?? null
  );

  const assignFile = useAssignFile(fileId);

  // TODO: refactor this, maybe usememo
  const getOptions = () => {
    const options = mapCompaniesToOptions(companies?.data ?? []);

    if (!preselectedCompany || debouncedSearchQuery !== '') {
      return options;
    }

    if (
      options.some((option) => option.value === preselectedCompany.companyId)
    ) {
      return options;
    }

    options.unshift({
      label: preselectedCompany.name, // todo: add address
      value: preselectedCompany.companyId
    });

    return options;
  };

  const handleAssignment = () => {
    if (!selectedCompanyId) {
      return;
    }

    assignFile.mutate(
      {
        belongsToUuid: selectedCompanyId,
        belongsToTable: 'company'
      },
      {
        onSuccess() {
          setOpen(false);
        }
      }
    );
  };
  return (
    <Modal
      title="Assign to company"
      open={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{
        disabled: !selectedCompanyId,
        loading: assignFile.isLoading
      }}
      onOk={handleAssignment}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '48px 16px',
          paddingTop: '16px'
        }}
      >
        <label htmlFor="company-select">Company</label>
        <Select
          id="company-select"
          options={getOptions()}
          style={{ width: '100%' }}
          placeholder="Surein GmbH"
          onSearch={(value) => setSearchQuery(value)}
          searchValue={searchQuery}
          value={selectedCompanyId}
          notFoundContent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0'
              }}
            >
              {isLoading ? <Spin /> : 'No companies found'}
            </div>
          }
          showSearch
          filterOption={false}
          allowClear
          onChange={(value) => setSelectedCompanyId(value)}
        />

        {!preselectedCompany && companyNameHint ? (
          <Alert
            type="info"
            message="You might be looking for:"
            description={
              <Typography.Text strong copyable={{ text: companyNameHint }}>
                {companyNameHint}
              </Typography.Text>
            }
          ></Alert>
        ) : null}
      </div>
    </Modal>
  );
};
