import { Input } from 'antd';

export interface FileListFiltersProps {
  searchQuery?: string;
  isLoading: boolean;
  onSearchChange: (value: string) => void;
}

export function FileListFilters({
  searchQuery,
  isLoading,
  onSearchChange
}: FileListFiltersProps) {
  return (
    <div style={{ marginBottom: 16, padding: '0 16px' }}>
      <Input.Search
        defaultValue={searchQuery}
        placeholder="Company name, Carrier name, Policy Number or File Name"
        allowClear
        loading={isLoading}
        onChange={(e) => onSearchChange(e.target.value || '')}
        size="large"
        style={{ maxWidth: '50%' }}
      />
    </div>
  );
}
