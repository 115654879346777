import { getFileHistory, getLocationHistory } from '@/api/actionsHistory';
import { ActionsHistory } from '@/types/actionsHistory';
import { useQuery, UseQueryOptions } from 'react-query';

const keys = {
  history: 'history'
};

export const useLocationHistory = (id: string, options: { enabled: boolean }) =>
  useQuery(
    [keys.history, 'location', id],
    () => getLocationHistory(id),
    options
  );

export const useFileHistory = (
  id: string,
  options?: UseQueryOptions<
    ActionsHistory[],
    unknown,
    ActionsHistory[],
    string[]
  >
) => useQuery([keys.history, 'file', id], () => getFileHistory(id), options);
