// @ts-expect-error html2pdf does not support TS
import html2pdf from 'html2pdf.js/dist/html2pdf.min';

const options = {
  margin: 0.5,
  pagebreak: { mode: 'legacy' },
  image: {
    type: 'jpeg',
    quality: 0.98
  },
  html2canvas: {
    scale: 2
  },
  jsPDF: {
    unit: 'in',
    format: 'a3',
    orientation: 'portrait'
  }
};

export async function renderPDF({
  content,
  filename
}: {
  content: string;
  filename: string;
}): Promise<Blob> {
  const html = `<html>
    <head>
      <title>PDF Cover Note</title>
    </head>
    <body>
      <div style="padding: 0; margin: 0; letter-spacing: 0.01px;">
        ${content}
      </div>
    </body>
  </html>`;

  return html2pdf()
    .from(html)
    .set({
      ...options,
      filename
    })
    .outputPdf('blob');
}
