import React from 'react';

import { PdfDocument } from '../PdfDocument/PdfDocument';
import { usePdfTopbar } from './hooks/usePdfTopbar';
import { PdfEditorViewBar } from './components/PdfEditorTopbar/PdfEditorViewBar';

import './styles.scss';

interface Props {
  pdf: Blob;
  fileName: string;
}

export const PdfEditorView = ({ pdf, fileName }: Props) => {
  const { zoomIn, zoomOut, scale } = usePdfTopbar();

  return (
    <div className="pdf-editor-view">
      <div className="pdf-editor-view__bar">
        <PdfEditorViewBar
          pdf={pdf}
          fileName={fileName}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          scale={scale}
        />
      </div>

      <div className="pdf-editor-view__pages">
        <PdfDocument pdf={pdf} scale={scale} />
      </div>
    </div>
  );
};
