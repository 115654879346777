import { FileWithRelatedEntities, GenericFile } from '@/api';
import { useProcessFile } from '@/services';
import {
  CheckCircleOutlined,
  FileTextOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  ShopOutlined
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  message,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CompanyTab } from './CompanyTab';
import { ContractTab } from './ContractTab';
import { GeneralTab } from './GeneralTab';
import { HistoryTab } from './HistoryTab';

type Pane = {
  title: string;
  icon: React.JSX.Element;
  content: ({ file }: FileWithRelatedEntities) => React.JSX.Element;
  requiresAttention?: (props: Omit<FileWithRelatedEntities, 'file'>) => boolean;
  showFooter?: boolean;
};

type TabsContainerProps = {
  fileWithRelatedEntities: FileWithRelatedEntities;
  fileList: GenericFile[];
};

export const tabs = [
  {
    title: 'File Info',
    icon: <InfoCircleOutlined />,
    content: GeneralTab
  },
  {
    title: 'Company Info',
    icon: <ShopOutlined />,
    content: CompanyTab,
    requiresAttention: ({ company }) => !company
  },
  {
    title: 'Contract Info',
    icon: <FileTextOutlined />,
    content: ContractTab,
    requiresAttention: ({ insurance }) => !insurance
  },
  {
    title: 'File History',
    icon: <HistoryOutlined />,
    content: HistoryTab,
    showFooter: false
  }
] satisfies Pane[];

export const FileTabs = ({
  fileWithRelatedEntities: { file, company, insurance },
  fileList
}: TabsContainerProps) => {
  return (
    <Tabs
      defaultActiveKey={tabs[0].title}
      tabPosition="left"
      style={{ height: '100%' }}
      items={tabs.map((pane) => {
        const TabContent = pane.content;

        return {
          key: pane.title,
          label: (
            <div style={{ position: 'relative' }}>
              {pane.icon}
              {pane.requiresAttention &&
                pane.requiresAttention({ company, insurance }) && (
                  <RequiresAttentionIndicator />
                )}
            </div>
          ),
          children: (
            <TabContainer
              title={pane.title}
              fileId={file.fileId}
              file={file}
              disableMarkingAsProcessed={!insurance}
              fileList={fileList}
              showFooter={pane.showFooter}
            >
              <TabContent file={file} company={company} insurance={insurance} />
            </TabContainer>
          )
        };
      })}
    />
  );
};

const TabContainer = ({
  title,
  children,
  fileId: fileId,
  file,
  disableMarkingAsProcessed,
  fileList,
  showFooter = true
}: {
  title: string;
  fileId: string;
  children: React.ReactNode;
  file: GenericFile;
  disableMarkingAsProcessed: boolean;
  fileList: GenericFile[];
  showFooter?: boolean;
}) => {
  const { mutate: processInboxFile } = useProcessFile(fileId);
  const [sendNotification, setSendNotification] = useState(file.visibleToUser);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSendNotification(file.visibleToUser);
  }, [file.visibleToUser]);

  const handleMarkAsProcessed = () =>
    processInboxFile(
      { sendNotification },
      {
        onSuccess: () => {
          message.success('File processed successfully');

          const possibleTargets = fileList.filter(
            (listingItem) => listingItem.fileId !== file.fileId
          );

          if (possibleTargets.length > 0) {
            history.push(
              `/documents/${possibleTargets[0].fileId}${location.search}`
            );
          } else {
            history.push(`/documents/${location.search}`);
          }
        }
      }
    );
  return (
    <div className="tab-container">
      <Typography.Title
        level={5}
        style={{
          margin: '16px 0 0 0'
        }}
      >
        {title}
      </Typography.Title>
      <Divider className="divider" />

      <div className="content">{children}</div>

      {showFooter && (
        <>
          <Divider className="divider" />

          <div className="footer">
            <Space
              direction="vertical"
              style={{ padding: '1rem' }}
              align="start"
            >
              {file.isProcessed ? (
                <Alert
                  message="File is already processed"
                  type="success"
                  showIcon
                />
              ) : (
                <>
                  <label
                    htmlFor="send-notification-switch"
                    style={{
                      marginBottom: '8px',
                      marginTop: '16px',
                      fontWeight: 500,
                      display: 'block',
                      cursor: 'pointer'
                    }}
                  >
                    User notification
                    <Tooltip
                      title={
                        sendNotification
                          ? 'Notification will be sent'
                          : 'Notification will not be sent'
                      }
                    >
                      <InfoCircleOutlined style={{ marginLeft: '0.25rem' }} />
                    </Tooltip>
                    <Switch
                      id="send-notification-switch"
                      style={{ marginLeft: '1rem' }}
                      checked={sendNotification}
                      onChange={setSendNotification}
                      disabled={!file.visibleToUser}
                    />
                  </label>

                  {disableMarkingAsProcessed && (
                    <Alert
                      message="You can't mark file as processed if it's not assigned to a contract"
                      type="warning"
                      showIcon
                    />
                  )}

                  <Button
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    disabled={disableMarkingAsProcessed}
                    onClick={handleMarkAsProcessed}
                  >
                    Mark as processed
                  </Button>
                </>
              )}
            </Space>
          </div>
        </>
      )}
    </div>
  );
};

const RequiresAttentionIndicator = () => {
  return (
    <div
      style={{
        width: '8px',
        height: '8px',
        backgroundColor: '#f5222d',
        borderRadius: '50%',
        position: 'absolute',
        top: -4,
        right: -4
      }}
    />
  );
};
