export const CategoryLabelMap: Record<string, string> = {
  LI: 'Liability insurance',
  CO: 'Contents insurance',
  LE: 'Legal insurance',
  CY: 'Cyber insurance',
  TR: 'Transport insurance',
  CA: 'Car insurance',
  BU: 'Building insurance',
  RE: 'Rent deposit insurance',
  HE: 'Company health insurance',
  PE: 'Company pension plan',
  EL: 'Electronics insurance',
  DO: 'D&O insurance',
  DI: 'Direct insurance',
  CC: 'Car contents insurance',
  GL: 'Glass insurance',
  HL: 'Home- and landowners liability insurance',
  HO: 'Homeowners insurance',
  TL: 'Term life insurance',
  AI: 'Accident insurance',
  EV: 'Event liability Insurance',
  FL: 'Financial loss liability insurance',
  PF: 'Professional liability insurance',
  BS: 'Business closure insurance',
  AL: 'Multi-peril insurance/multiline',
  CL: 'Club liability insurance',
  BP: 'Basic pension (unit-linked)',
  TH: 'Travel health insurance',
  EX: 'Exhibition insurance',
  BL: "Builder's liability insurance",
  PR: 'Building performance insurance',
  DS: 'Disability insurance',
  IN: 'Business interruption insurance',
  CR: 'Carrier liability insurance',
  DB: 'Daily benefits insurance',
  HI: 'Health insurance',
  SH: 'Statutory health insurance',
  CH: 'Comprehensive health insurance',
  SU: 'Supplementary health insurance',
  MI: 'Machinery insurance',
  LC: 'Long-term care insurance',
  PL: 'Personal liability insurance',
  TC: 'Travel cancellation insurance',
  AN: 'Annuity insurance (unit-linked)',
  RP: 'Riester pension (unit-linked)',
  AO: 'Animal owner liability insurance',
  AH: 'Animal health insurance',
  LF: 'Life insurance (unit-linked)',
  OD: 'Occupational disability insurance',
  WD: 'Water damage liability insurance',
  BC: 'Basic pension (classic)',
  SV: 'Building savings contract',
  OL: "Operator's liability insurance",
  LP: 'Loss of profit insurance',
  CP: 'Camping insurance',
  LO: 'Loan contract',
  EM: "Employer's liability insurance",
  DR: 'Dread disease',
  FU: 'Funds',
  CM: 'Camera insurance',
  WA: 'Warranty insurance',
  BA: 'Basic Abilities Insurance',
  HU: 'Hunting liability insurance',
  DH: 'Daily hospital allowance insurance',
  AR: 'Art insurance',
  SP: 'Spa costs insurance/curative daily allowance',
  LU: 'Life insurance (different from Lebensversicherung (fondsgebunden))',
  LL: 'life insurance (classic)',
  RL: 'Rent loss insurance',
  MU: 'Music instrument insurance',
  NC: 'Nursing care insurance',
  DN: 'Daily nursing allowance insurance',
  BG: 'Baggage insurance',
  PC: 'Pension plan (classic)',
  ST: 'Statutory pension insurance',
  RI: 'Riester pension (classic)',
  PO: 'Protective cover insurance',
  ML: 'Marine liability insurance',
  OT: 'Other lines',
  SB: 'Sport boat hull insurance',
  DE: 'Death benefit insurance',
  TE: 'Technical insurance',
  AP: 'Accident insurance with premium refund',
  AM: 'Accident insurance/multi-risk policy',
  TA: 'Traffic liability insurance',
  PP: 'Pension plan',
  VSV: 'Fidelity Insurance'
};

export const PlainCategoryLabelMap: Record<string, string> = {
  LI: 'Liability',
  CO: 'Contents',
  LE: 'Legal',
  CY: 'Cyber',
  TR: 'Transport',
  CA: 'Car',
  BU: 'Building',
  RE: 'Rent deposit',
  HE: 'Company health',
  PE: 'Company pension plan',
  EL: 'Electronics',
  DO: 'D&O',
  DI: 'Direct',
  CC: 'Car contents',
  GL: 'Glass',
  HL: 'Home- and landowners liability',
  HO: 'Homeowners',
  TL: 'Term life',
  AI: 'Accident',
  EV: 'Event liability',
  FL: 'Financial loss liability',
  PF: 'Professional liability',
  BS: 'Business closure',
  AL: 'Multi-peril/multiline',
  CL: 'Club liability',
  BP: 'Basic pension (unit-linked)',
  TH: 'Travel health',
  EX: 'Exhibition',
  BL: "Builder's liability",
  PR: 'Building performance',
  DS: 'Disability',
  IN: 'Business interruption',
  CR: 'Carrier liability',
  DB: 'Daily benefits',
  HI: 'Health',
  SH: 'Statutory health',
  CH: 'Comprehensive health',
  SU: 'Supplementary health',
  MI: 'Machinery',
  LC: 'Long-term care',
  PL: 'Personal liability',
  TC: 'Travel cancellation',
  AN: 'Annuity (unit-linked)',
  RP: 'Riester pension (unit-linked)',
  AO: 'Animal owner liability',
  AH: 'Animal health',
  LF: 'Life (unit-linked)',
  OD: 'Occupational disability',
  WD: 'Water damage liability',
  BC: 'Basic pension (classic)',
  SV: 'Building savings contract',
  OL: "Operator's liability",
  LP: 'Loss of profit',
  CP: 'Camping',
  LO: 'Loan contract',
  EM: "Employer's liability",
  DR: 'Dread disease',
  FU: 'Funds',
  CM: 'Camera',
  WA: 'Warranty',
  BA: 'Basic Abilities Insurance',
  HU: 'Hunting liability',
  DH: 'Daily hospital allowance',
  AR: 'Art',
  SP: 'Spa costs/curative daily allowance',
  LU: 'Life (different from Lebensversicherung (fondsgebunden))',
  LL: 'life (classic)',
  RL: 'Rent loss',
  MU: 'Music instrument',
  NC: 'Nursing care',
  DN: 'Daily nursing allowance',
  BG: 'Baggage',
  PC: 'Pension plan (classic)',
  ST: 'Statutory pension',
  RI: 'Riester pension (classic)',
  PO: 'Protective cover',
  ML: 'Marine liability',
  OT: 'Other lines',
  SB: 'Sport boat hull',
  DE: 'Death benefit',
  TE: 'Technical',
  AP: 'Accident with premium refund',
  AM: 'Accident/multi-risk policy',
  TA: 'Traffic liability',
  PP: 'Pension plan',
  VSV: 'Fidelity Insurance'
};

export const autoRecommendationKeys = [
  'LI',
  'CO',
  'LE',
  'FL',
  'CY',
  'EL'
] as const;
