import React, { useState } from 'react';

import {
  BusinessTypeSelector,
  Loader,
  LocationAutocomplete
} from '@/components';
import { useCreateCompany, useListUsers } from '@/services';
import { Company } from '@/types/company';
import { locationAutocompleteRule } from '@/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { evolve, map } from 'ramda';
import './styles.scss';
import { makeBusinessTypePayload } from '@/components/BusinessTypeSelector/helpers';
import { CreateCompanyPayload } from '@/api';

interface AddCompanyModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userId?: string;
  onSuccess?: (company: Company) => void;
}

const initialValues = {
  businessType: {
    businessType: '',
    locationType: '',
    locationSubtypes: [],
    locationTypeSpecification: ''
  }
};

const modifier = evolve({
  businessType: makeBusinessTypePayload
});

export const AddCompanyModal = ({
  visible,
  setVisible,
  userId,
  onSuccess
}: AddCompanyModalProps) => {
  const [form] = Form.useForm();
  const createCompanyMutation = useCreateCompany();
  const { data: users, isLoading } = useListUsers<
    { label: string; value: string }[]
  >({
    enabled: !userId,
    select: map(({ auth0, email }) => ({
      label: email,
      value: auth0
    }))
  });

  if (isLoading) return <Loader />;

  const createCompany = () =>
    form.validateFields().then((values) => {
      createCompanyMutation
        .mutateAsync(modifier({ ...values, userId }) as CreateCompanyPayload)
        .then((res) => {
          setVisible(false);
          onSuccess?.(res?.company?.item);
        });
    });

  const handleSameAddress = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['location', 'address'],
        form.getFieldValue(['company', 'address'])
      );
    } else {
      form.setFieldValue(['location', 'address'], { country: 'Germany' });
    }
  };

  return (
    <Modal
      centered
      onCancel={() => setVisible(false)}
      onOk={createCompany}
      okText="Firma hinzufügen"
      cancelText="Abbrechen"
      title="Neue Firma"
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        {!userId && (
          <Form.Item
            label="Owner"
            name="userId"
            rules={[{ required: true, message: 'Required!' }]}
          >
            <Select
              showSearch
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  ?.toUpperCase()
                  .startsWith(inputValue?.toUpperCase())
              }
              options={users}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Company Name"
          name={['company', 'name']}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Company Address"
          name={['company', 'address']}
          rules={[
            { required: true, message: 'Required!' },
            locationAutocompleteRule
          ]}
        >
          <LocationAutocomplete name={['company', 'address']} />
        </Form.Item>
        <Form.Item
          label="Location Name"
          name={['location', 'name']}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Location Address"
          name={['location', 'address']}
          rules={[
            { required: true, message: 'Required!' },
            locationAutocompleteRule
          ]}
        >
          <LocationAutocomplete
            name={['location', 'address']}
            handleSame={handleSameAddress}
          />
        </Form.Item>
        <BusinessTypeSelector />
      </Form>
    </Modal>
  );
};

interface AddCompanyProps {
  userId?: string;
  onSuccess?: (company: Company) => void;
}

export const AddCompany = ({ userId, onSuccess }: AddCompanyProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        size="large"
        type="primary"
        onClick={() => setVisible(true)}
        icon={<PlusCircleOutlined />}
      >
        New Company
      </Button>
      {visible && (
        <AddCompanyModal
          visible={visible}
          setVisible={setVisible}
          userId={userId}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
