import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import Sider from 'antd/lib/layout/Sider';

import { useGetFileWithRelatedEntities } from '@/services';
import './Sidebar.styles.scss';
import { FileTabs } from './Tabs';
import { GenericFileWithRelatedEntities } from '@/api';

export function Sidebar({
  unprocessedFiles = []
}: {
  unprocessedFiles: GenericFileWithRelatedEntities[] | undefined;
}) {
  const { fileId } = useParams<{ fileId: string }>();

  const { data: fileWithRelatedEntities, isLoading } =
    useGetFileWithRelatedEntities(fileId);

  return (
    <Sider theme="light" width={400} className="tabs-sidebar">
      {isLoading || !fileWithRelatedEntities ? (
        <Skeleton active />
      ) : (
        <FileTabs
          fileWithRelatedEntities={fileWithRelatedEntities}
          fileList={unprocessedFiles}
        />
      )}
    </Sider>
  );
}
