export const fileTypes: Record<string, string> = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg'
};

export const fileCategories = {
  application: 'Antragsdokumente',
  broker_authorisation: 'Maklervollmacht',
  cancellation: 'Kündigung',
  change_of_partner: 'Partneränderung',
  change_request: 'Änderungsantrag',
  claim: 'Schadendokumente',
  complaint: 'Beschwerde',
  contact: 'Kontakt',
  contract_amendment: 'Vertragsänderung',
  contract: 'Vertragsdokumente',
  cover_note: 'Deckungsnote',
  gdv_data: 'GDV-Daten',
  incident: 'Störfälle',
  information: 'Auskünfte',
  invoice: 'Beitragsrechnungen',
  legal_relationships: 'Rechtsverhältnisse',
  offer: 'Angebotsdokumente',
  portfolio_transfer: 'Bestandsübertragung',
  risk_acquisition: 'Risikoerfassung',
  vu_vm_settlement: 'VU-VM Abrechnung',
  contribution_arrears: 'Beitragsrückstand',
  other: 'Sonstiges',
  risk_recording: 'Risikoerfassung'
};

export type FileCategory = keyof typeof fileCategories;
