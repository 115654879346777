import { fileCategories } from '@/enums/fileTypes';
import { useFileHistory } from '@/services';
import {
  ActionsHistory,
  FileAssignmentData,
  FileNotificationData,
  FileUpdateData
} from '@/types/actionsHistory';
import { Empty, Timeline } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const getFileAssignmentMessage = (
  actionExtraData: FileAssignmentData
): string => {
  if (actionExtraData?.assignedEntity === 'company') {
    return `assigned to company ${actionExtraData?.newValue}`;
  }
  if (actionExtraData?.assignedEntity === 'insurance') {
    return `assigned to contract ${actionExtraData?.newValue}`;
  }
  return 'assigned the file to an unknown entity';
};

const getFileUpdateMessage = (actionExtraData: FileUpdateData): string => {
  const messages: Record<string, string> = {
    category: `changed file category to ${fileCategories?.[actionExtraData?.newValue as keyof typeof fileCategories] || actionExtraData?.newValue}`,
    display_name: `changed file name to ${actionExtraData?.newValue}`,
    visible_to_user: `changed user visibility to ${actionExtraData?.newValue === true ? 'visible' : 'hidden'}`,
    description: `changed description to ${actionExtraData?.newValue}`
  };

  return (
    messages[actionExtraData?.editedProperty] ||
    `edited property ${actionExtraData?.editedProperty} to ${actionExtraData?.newValue}`
  );
};

const getFileNotificationMessage = (
  actionExtraData: FileNotificationData
): string => {
  return actionExtraData.recipients.length
    ? `sent file notification to ${actionExtraData.recipients.join(', ')}`
    : 'attempted to send file notification but no recipients were found';
};

const getActionMessage = (action: ActionsHistory): string => {
  const messages: Record<string, string | ((actionExtraData: any) => string)> =
    {
      document_uploaded: 'uploaded document',
      file_assigned: (extraData) => getFileAssignmentMessage(extraData),
      file_updated: (extraData) => getFileUpdateMessage(extraData),
      file_processed: 'marked file as processed',
      file_notification_sent: (extraData) =>
        getFileNotificationMessage(extraData)
    };

  const messageHandler = messages[action.actionType];

  if (typeof messageHandler === 'function') {
    return messageHandler(action.actionExtraData);
  }

  return messageHandler || action.actionType.toLowerCase();
};

const formatHistoryItem = (item: ActionsHistory): string => {
  const formattedDate = dayjs(item.createdAt).format('DD-MM-YYYY hh:mma');
  const actionMessage = getActionMessage(item);
  return `${item.actionExtraData?.adminName} ${actionMessage} - ${formattedDate}`;
};

export const HistoryTab = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { data, isLoading } = useFileHistory(fileId);

  if (isLoading) return <div>Loading...</div>;

  if (!data?.length) {
    return (
      <Empty
        description="No history entries yet"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ margin: '2rem 0' }}
      />
    );
  }

  return (
    <div>
      <Timeline>
        {data?.map((item) => (
          <Timeline.Item key={item.id}>{formatHistoryItem(item)}</Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};
