import { SearchAwareLink } from '@/components/SearchAwareLink';
import { Card as AntCard } from 'antd';

interface CardProps {
  fileId: string;
  title: string;
  description: string;
  onClick?: () => void;
  className?: string;
  isActive?: boolean;
}

export function FileCard({
  fileId,
  title,
  description,
  className,
  isActive,
  ...props
}: CardProps) {
  return (
    <SearchAwareLink style={{ width: '100%' }} to={`/documents/${fileId}`}>
      <AntCard
        hoverable
        className={`${className || ''} file-card ${isActive ? 'file-card--active' : ''}`.trim()}
        {...props}
      >
        <AntCard.Meta
          title={title}
          description={
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                lineHeight: '1.4em',
                maxHeight: '2.8em'
              }}
            >
              <span title={description}>{description}</span>
            </div>
          }
        />
      </AntCard>
    </SearchAwareLink>
  );
}
